import { Controller } from '@hotwired/stimulus'
import SmoothScroll from 'smooth-scroll'

const VIEW_LIMIT = 5

export default class extends Controller {
  static targets = ['faq', 'open', 'close', 'hide', 'show']

  initialize () {
    this.faqTargets.forEach((faq, index) => {
      if (index >= VIEW_LIMIT) {
        this.showTarget.style.display = 'flex'
        faq.classList.add(`${this.className()}::hidden`)
      }
    })

    this.scroll = new SmoothScroll(null, {
      offset: 55,
      speed: 600,
      speedAsDuration: true,
      easing: 'linear',
      updateURL: false,
      popstate: false,
    })
  }

  className () {
    return this.element.dataset.classname
  }

  toggle (e) {
    const target = e.target.parentNode

    if (target.classList.contains(`${this.className()}::accordion-close`)) {
      this.open(target)
    } else {
      this.close(target)
    }
  }

  open (target) {
    target.classList.remove(`${this.className()}::accordion-close`)
    target.classList.add(`${this.className()}::accordion-open`)
  }

  close (target) {
    target.classList.remove(`${this.className()}::accordion-open`)
    target.classList.add(`${this.className()}::accordion-close`)
  }

  show () {
    this.faqTargets.forEach((faq, index) => {
      if (index >= VIEW_LIMIT) {
        faq.classList.remove(`${this.className()}::hidden`)
      }
    })
    this.showTarget.style.display = 'none'
    this.hideTarget.style.display = 'flex'
  }

  hide () {
    this.faqTargets.forEach((faq, index) => {
      if (index >= VIEW_LIMIT) {
        faq.classList.add(`${this.className()}::hidden`)
      }
    })
    this.showTarget.style.display = 'flex'
    this.hideTarget.style.display = 'none'
  }

  scrollTo (id) {
    const anchor = document.querySelector(id)
    this.scroll.animateScroll(anchor)
  }

  scrollToInfo (e) {
    e.preventDefault()
    this.scrollTo('#info')
  }

  scrollToPhotos (e) {
    e.preventDefault()
    this.scrollTo('#photos')
  }

  scrollToFeatures (e) {
    e.preventDefault()
    this.scrollTo('#features')
  }

  scrollTofaqs (e) {
    e.preventDefault()
    this.scrollTo('#faqs')
  }

  scrollToReviews (e) {
    e.preventDefault()
    this.scrollTo('#reviews')
  }

  scrollToOccupantInterviews (e) {
    e.preventDefault()
    this.scrollTo('#occupant_interviews')
  }

  scrollToAccess (e) {
    e.preventDefault()
    this.scrollTo('#access')
  }
}
